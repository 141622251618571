import React, { useState } from 'react';
import 'components/contact/Contact.css';
import Layout from 'components/layout';
import Box from 'components/box';
import Footer from 'components/footer/Footer';
import Title from 'components/title';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { graphql } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

const Contact = ({ data }) => {

  const [result, setResult] = useState('');
  const [state, setState] = useState({});

  const locales = data.contactJson.en;
  const classes = useStyles();

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        setResult(locales.successfulMessage);
        // navigate(form.getAttribute('action'))
      })
      .catch((error) => setResult(locales.errorMessage));
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <Layout>
      <div className="layout-div">
        <Title as="h2" size="large">
          {locales.title}
        </Title>
      </div>
      <div style={{ height: '40vh' }}>
        <Box>
          <form name="contact"
                method="POST"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className={classes.root}
                onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact"/>
            <TextField name="name" label={locales.name} required onChange={handleChange}/>
            <TextField type={'email'} name="email" label={locales.email} required onChange={handleChange}/>
            <TextField type={'textarea'} name="message" fullWidth
                          placeholder={locales.placeholderMessage}
                          multiline={true}
                          rows={2}
                          rowsMax={4} required onChange={handleChange}/>
            <Button type="submit" variant="contained">{locales.send}</Button>
          </form>
          <br/>
          <span className="source-light">{result}</span>
        </Box>
      </div>
      <div style={{ height: '20vh' }}/>
      <Footer footers={locales.footers}/>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query ContactEnQuery {
    contactJson {
      en {
        title
        errorMessage
        successfulMessage
        name
        email
        placeholderMessage
        send
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    homeJson {
      en {
        footers {
          title
          description {
            label
            link
          }
        }
      }
    }
  }
`;
